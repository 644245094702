import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Floorball from "@/views/Floorball";
import Accessories from "@/views/Accessories";
import Login from "@/views/Login";
import Cart from "@/views/Cart";
import All from "@/views/All";
import ProductDetails from "@/views/ProductDetails";
import Admin from "@/views/admin/Admin";
import Ordered from "@/views/Ordered";
import Checkout from "@/views/checkout/Checkout";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/floorball',
    name: 'floorball',
    component: Floorball,
    props: true
  },
  {
    path: '/floorball/:type/:id',
    name: 'FloorballDetails',
    component: ProductDetails,
    props: true
  },
  {
    path: '/accessories/:type/:id',
    name: 'AccessoriesDetails',
    component: ProductDetails,
    props: true
  },
  {
    path: '/accessories',
    name: 'accessories',
    component: Accessories
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    component: Checkout,
    props: true
  },
  {
    path: '/cart/ordered',
    name: 'Ordered',
    component: Ordered,
    props: true
  },
  {
    path: '/all',
    name: 'All',
    component: All
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
