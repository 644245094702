<template>
  <v-text-field
      outlined
      class="rounded-0"
      :label="label"
      :type="type"
      :rules="rules"
      :prepend-inner-icon="icon"
      :prefix="prefix"
      :placeholder="placeholder"
      :value="value"
      :readonly="readonly"
      :maxlength="maxlength"
      v-on:input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: "TextField",
  props: {
    label: {
      type: String,
    },
    type: {
      type: String
    },
    rules: {
      type: Array
    },
    icon: {
      type: String
    },
    prefix: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    maxlength: {
      type: Number
    }
  }
}
</script>

<style scoped>

</style>
