const state = {
    cartItems: []
}
const getters = {
    cartItems: (state) => state.cartItems,
    cartItemsSize: (state) => state.cartItems?.length,
}
const actions = {
    incrementCartItem({commit}, product) {
        commit('incrementCartItem', product)
    },
    decrementCartItem({commit}, product) {
        commit('decrementCartItem', product)
    },
    removeCartItem({commit}, product) {
        commit('removeCartItem', product)
    },
    addCartItem({commit}, product) {
        commit('addCartItem', product)
    },
    resetCartItems({commit}) {
        commit('resetCartItems');
    }
}
const mutations = {
    incrementCartItem: (state, product) => {
        const productId = state.cartItems.findIndex(item => item.id === product.id);
        if (productId === -1) {
            state.cartItems.push({...product, cartQty: 1});
            return;
        }
        state.cartItems[productId].cartQty = state.cartItems[productId]?.cartQty + 1;
    },
    addCartItem: (state, product) => {
        const productId = state.cartItems.findIndex(item => item.id === product.id);
        if (productId === -1) {
            state.cartItems.push({...product, cartQty: product?.cartQty});
            return;
        }
        state.cartItems[productId].cartQty = state.cartItems[productId]?.cartQty + product?.cartQty;
    },
    decrementCartItem: (state, product) => {
        const productId = state.cartItems.findIndex(item => item.id === product.id);
        if (productId === -1) return;
        state.cartItems[productId].cartQty = state.cartItems[productId]?.cartQty - 1;
        if (state.cartItems[productId]?.cartQty < 1) {
            state.cartItems.splice(productId, 1);
        }
    },
    removeCartItem: (state, product) => {
        const productId = state.cartItems.findIndex(item => item.id === product.productId);
        if (productId === -1) return;
        state.cartItems[productId].cartQty = 0;
        if (state.cartItems[productId]?.cartQty < 1) {
            state.cartItems.splice(productId, 1);
        }
    },
    resetCartItems: (state) => {
        state.cartItems = [];
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}


