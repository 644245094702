<template>
  <div>
    <div class="pt-8">
      <h1 class="my-4 mx-8">Shopping Cart</h1>
      <v-row v-if="cartItems.length === 0" class="my-8">
        <v-col class="grey lighten-5 pa-16" align="center" justify="center">
          <v-icon size="240" color="primary">mdi-cart-outline</v-icon>
          <h1 class="ma-4">Your Cart Is Currently Empty!</h1>
          <span class="ma-4 grey--text text--darken-1">Before proceed to checkout you must add some products to your shopping cart.
            <br/> You will find a lot of interesting products on our "Floorball" page.
            </span><br/>
          <Button title="Return to Floorball" class="my-8" @click="onReturnToFloorball"/>
        </v-col>
      </v-row>
      <v-row v-else class="my-8">
        <v-col class="grey lighten-5 px-8">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <CartItem
                  v-for="(cartItem, index) in cartItems"
                  :cartItem="cartItem"
                  :key="index"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-flex sm4>
          <v-col class="pa-12 grey lighten-4" align="center">
            <h2 class="font-weight-regular">Order Summary</h2>
            <v-row class="my-8">
              <span
                  class="text-uppercase grey--text text--darken-2 font-weight-bold ma-1">{{
                  totalItems + ' items'
                }}</span>
              <v-spacer/>
              <span class="ma-1">{{ '$ ' + totalPrice }}</span>
            </v-row>
            <v-row class="text-uppercase grey--text text--darken-2 font-weight-bold">shipping</v-row>
            <v-row>
              <Dropdown
                  v-model="shipping"
                  :items="shippingItems"
                  :rules="shippingRules"
                  class="my-2 mx-1"
              />
            </v-row>
            <v-row class="text-uppercase grey--text text--darken-2 font-weight-bold">promo code</v-row>
            <v-row class="my-2">
              <v-text-field
                  placeholder="Enter your code"
                  solo
                  class="my-1 rounded-0 mx-1"
                  background-color="py-3 px-4"
                  :error-messages="promoCodeError"
              />
              <Button title="Apply" type="apply" class="ma-1 pa-7" @click="onApply"/>
            </v-row>
            <v-row class="my-8">
              <span class="text-uppercase grey--text text--darken-2 font-weight-bold ma-1">total cost</span>
              <v-spacer/>
              <span class="ma-1">{{ '$ ' + totalPrice }}</span>
            </v-row>
            <v-row>
              <Button title="Checkout" type="blocked" @click="onCheckout"/>
            </v-row>

          </v-col>
        </v-flex>
      </v-row>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import Button from "@/components/Button";
import CartItem from "@/views/CartItem";
import {mapGetters} from "vuex";
import router from "@/router";

export default {
  components: {CartItem, Button, Dropdown},
  data() {
    return {
      shipping: 'Standard Delivery (+$10.00)',
      shippingItems: ['Standard Delivery (+$10.00)'],
      shippingCosts: [10.00, 0.00],
      shippingRules: [v => v != null && v?.length > 0 || 'Shipping option is required'],
      promoCodeError: ''
    }
  },
  computed: {
    ...mapGetters(['cartItems']),
    totalItems() {
      const total = this.cartItems.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue.cartQty;
      }, 0);
      return total;
    },
    totalPrice() {
      let total = this.cartItems.reduce(function (accumulator, currentValue) {
        const price = currentValue?.price;
        return accumulator + (currentValue.cartQty * price);
      }, 0);
      const index = this.shippingItems?.findIndex(item => item === this.shipping);
      total += this.shippingCosts[index];
      return parseFloat(total).toFixed(2);
    }
  },
  methods: {
    onReturnToFloorball() {
      router.push({name: 'floorball'})
    },
    onCheckout() {
      // alert('Checkout not available at the moment');
      const shippingMode = this.shipping;
      const totalPrice = this.totalPrice;
      router.push({name: 'Checkout', params: {shippingMode, totalPrice}})
    },
    onApply() {
      this.promoCodeError = 'This code is not valid';
    }
  }
}
</script>

<style scoped>

</style>
