<template>
  <div class="pa-8 white">
    <v-row>
      <v-img contain max-width="200" max-height="200" :src="imageUrl"
             class="elevation-2 ma-4"/>
      <v-col>
        <span class="mx-1 my-8">Title</span>
        <v-text-field
            :value="title"
            solo
            :disabled="!isEditing"
            :outlined="isEditing"
            background-color="elevation-0 rounded-0"
        />
        <span class="mx-1 my-8">Summary</span>
        <v-textarea
            :value="summary"
            solo
            :disabled="!isEditing"
            :outlined="isEditing"
            background-color="elevation-0 rounded-0"
            no-resize
            auto-grow
        />
        <span class="mx-1 my-8">Brand</span>

      </v-col>
      <div class="pa-2 ml-16" v-if="!isEditing">
        <v-icon @click="onEdit" color="primary">mdi-pencil</v-icon>
      </div>
      <div class="pa-2" v-if="isEditing">
        <v-icon @click="onEdit" class="ma-2" color="success">mdi-check</v-icon>
        <v-icon @click="onEdit" class="ma-2" color="error">mdi-close</v-icon>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['product'],
  data() {
    return {
      isEditing: false
    }
  },
  computed: {
    title() {
      return this.product?.title;
    },
    summary() {
      return this.product?.summary;
    },
    imageUrl() {
      return this.product?.imageUrl;
    },
    brand() {
      return this.product?.brand;
    },
    price() {
      return this.product?.price;
    },
    subtype() {
      return this.product?.subtype;
    },
    type() {
      return this.product?.type;
    },
  },
  methods: {
    onEdit() {
      this.isEditing = !this.isEditing;
    }
  }
}
</script>

<style scoped>

</style>
