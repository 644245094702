<template>
  <div class="ma-16">
    <Dialog
        title="Title"
        :dialog="productDialog"
        @close="productDialog=false"
    >
      <AdminDialog :product="selectedProduct"/>

    </Dialog>
    <li v-for="(data, index) in output" :key="index">
      {{ data }}
    </li>
    <v-row>
      <div v-for="(product, index) in products" :key="index">
        <Card
            :product="product"
            @click="onClick(product)"
            :onCardClick="true"
        />
      </div>
    </v-row>
  </div>
</template>

<script>
import {data} from '../../data'
import {addProduct, getAllProducts, getProduct} from "@/db/products";
import Card from "@/components/Card";
import Dialog from "@/components/Dialog";
import AdminDialog from "@/views/admin/AdminDialog";

export default {
  components: {AdminDialog, Dialog, Card},
  async created() {
    this.products = await getAllProducts("floorball");
    for (let index in data) {
      const id = this.getValue(data[index], 'g:id');
      const imageUrl = this.getValue(data[index], 'g:image_link');
      const title = this.getValue(data[index], 'title');
      const price = this.getValue(data[index], 'g:price');
      const summary = this.getValue(data[index], 'summary');
      const brand = this.getValue(data[index], 'g:brand');
      const subtype = this.getValue(data[index], 'g:product_type').split('>')[0].trim().toLowerCase();
      let type = this.accessories?.includes(subtype) ? 'accessories' : 'floorball';
      let product = await getProduct(id);
      // this.output.push('checking ' + index +  ' / ' + subtype);
      if (product === null) {
        let productId = await addProduct(id, {imageUrl, title, price, summary, type, subtype, brand, quantity: 1});
        this.output.push('Added ' + productId + ' - ' +  title +' /n')
      }
    }
  },
  data() {
    return {
      output: [],
      accessories: ['accessories', 'bags and toolbags'],
      selectedProduct: null,
      products: null,
      productDialog: false
    }
  },
  methods: {
    onClick(product) {
      this.selectedProduct = product;
      this.productDialog = true;
    },
    getValue(data, field) {
      const value = data?.elements.find(item => item.name === field)?.elements?.find(item => item.type === 'text')?.text;
      return value ? value : null;
    },
  }
}
</script>

<style scoped>

</style>
