<template>
  <div>
    <v-row class="ma-8">
      <v-col class="pa-8 grey lighten-4 pt-16" align="center">
        <v-icon size="120" color="success">mdi-checkbox-marked-circle-outline</v-icon>
        <h3 class="ma-2">Thank you for your purchase!</h3><br/>
        <span class="font-weight-regular">Your order number is: {{ orderId }}</span><br/>
        <span class="font-weight-regular grey--text text--darken-2">We'll email you an order confirmation with details and tracking info</span>
        <br/>
        <Button title="Continue Shopping" class="mt-8 mb-16" @click="onContinueShopping"></Button>
<!--        <h3 class="mt-8">Create account for next time</h3><br/>-->
<!--        <span class="font-weight-regular">You can track your order status by creating an account.</span><br/>-->
<!--        <span-->
<!--            class="font-weight-regular grey&#45;&#45;text text&#45;&#45;darken-2">Email address: <b>ghurabah93@hotmail.com</b></span><br/>-->
<!--        <Button title="Create an Account" class="mt-12 mb-12"></Button>-->
      </v-col>
      <v-col class="pa-8 grey lighten-5">
        <v-simple-table class="v-sheet--outlined">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left grey--text text--darken-3">
                Product Name
              </th>
              <th class="text-left  grey--text text--darken-3">
                Price
              </th>
              <th class="text-left  grey--text text--darken-3">
                Qty
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in orderedItems" :key="index">
              <td class="pa-4">{{ item && item.title }}</td>
              <td>{{ item && item.price && ('$ ' + item.price) }}</td>
              <td>{{ item && item.cartQty }}</td>
            </tr>
            </tbody>
            <tfoot>
            <th class="grey lighten-3 mr-4 font-weight-regular">
              <v-col justify="end" align="end">
                <br/><span>Subtotal</span><br/><br/>
                <span>Shipping</span><br/><br/>
                <span class="font-weight-bold">Grand Total</span><br/><br/>
              </v-col>
            </th>
            <th class="grey lighten-3 font-weight-regular">
              <v-col justify="end" align="end">
                <br/><span>{{ '$ ' + (totalPrice - 10.00).toFixed(2) }}</span><br/><br/>
                <span>$ 10.00</span><br/><br/>
                <span class="font-weight-bold">{{ '$ ' + totalPrice }}</span><br/><br/>
              </v-col>
            </th>
            <th class="grey lighten-3">
            </th>
            </tfoot>
          </template>
        </v-simple-table>
        <v-col class="my-4 px-0">
          <span>Order Date: {{ date }}</span>
          <h3 class="my-8">Order Information</h3>
          <v-simple-table class="v-sheet--outlined">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left grey--text text--darken-3">
                  Shipping Address
                </th>
                <th class="text-left  grey--text text--darken-3">
                  Shipping Method
                </th>
                <th class="text-left  grey--text text--darken-3">
                  Billing Address
                </th>
                <th class="text-left  grey--text text--darken-3">
                  Payment Method
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{ shippingAddress }}</td>
                <td>{{ shippingMode }}</td>
                <td>{{ billingAddress }}</td>
                <td>{{ paymentMode }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-col>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import Button from "@/components/Button";
import moment from "moment";
import router from "@/router";

export default {
  components: {Button},
  props: ['orderId', 'shippingMode','shippingAddress','billingAddress','paymentMode', 'totalPrice', 'orderedItems'],
  created() {
    if (!this.orderId) {
      router.push({name: 'Home'});
    }
  },
  methods: {
    onContinueShopping() {
      router.push({name: 'Home'});
    }
  },
  computed: {
    price: function (item) {
      return item?.price;
    },
    title: function (item) {
      return item?.title;
    },
    cartQty: function (item) {
      return item?.cartQty;
    },
    date() {
      const date = new Date();
      return moment(date).format("dddd, DD MMMM YYYY");
    }
  },
}
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
