<template>
  <v-app id="app">
    <nav>
      <v-app-bar app color="secondary" class="elevation-2">
        <v-tabs align-with-title>
          <v-tab to="/" class="black--text ma-0 mx-0">
            <v-img class="mt-4" src="./assets/efloorball-logo.jpg" max-width="120"></v-img>
          </v-tab>
          <v-spacer></v-spacer>
          <v-tab to="/floorball" class="text-none pa-6"><h3>Floorball</h3></v-tab>
          <v-tab to="/accessories" class="text-none pa-6"><h3>Accessories</h3></v-tab>
          <v-spacer></v-spacer>
          <!--          <v-tab to="/login" class="text-none pa-6"><h3>Login</h3></v-tab>-->
          <v-tab to="/cart" class="text-none pa-6">
            <v-row align="center">
              <v-badge
                  color="primary"
                  :value="cartItemsSize > 0"
                  :content="cartItemsSize"
                  class="mx-2"
              >
                <v-icon>mdi-cart</v-icon>
              </v-badge>
              <h3>Cart</h3>
            </v-row>

          </v-tab>
        </v-tabs>
      </v-app-bar>
    </nav>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'App',
  computed: {
    ...mapGetters(['cartItemsSize'])
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');

html, body {
  font-family: 'Montserrat', sans-serif;
}

#app {
  font-family: 'Montserrat', sans-serif;
}
</style>
