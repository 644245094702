<template>
  <v-select
      class="rounded-0"
      background-color="white elevation-4 py-3 px-4"
      solo
      v-bind:items="items"
      v-bind:label="label"
      v-on:input="$emit('input', $event)"
      :error-messages="error"
      :rules="rules"
      :value="value"
      flat
      @change="onChange($event)"
  ></v-select>
</template>

<script>
export default {
  name: "Dropdown",
  props: ['label', 'items', 'error', 'rules', 'value'],
  methods: {
    onChange(event) {
      this.$emit('change', event);
    }
  }
}
</script>

<style scoped>

</style>
