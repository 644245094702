import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/remote-config'

// firebase init
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIRESTORE_API_KEY,
    authDomain: process.env.VUE_APP_FIRESTORE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIRESTORE_DB_URL,
    projectId: process.env.VUE_APP_FIRESTORE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIRESTORE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIRESTORE_MESSENGER_ID,
    appId: process.env.VUE_APP_FIRESTORE_APP_ID,
    measurementId: process.env.VUE_APP_FIRESTORE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
db.enablePersistence();

// const auth = firebase.auth();
// const remoteConfig = firebase.remoteConfig();
// const storage = firebase.storage();

// collection references
const productsCollection = db.collection('products');
const ordersCollection = db.collection('orders');

// export utils/refs
export {
    db,
    productsCollection,
    ordersCollection,
    // organisationsCollection,
}
