<template>
  <v-layout align-center column class="grey lighten-4 pb-8">
    <h1 class="my-8 mx-8 font-weight-regular">Checkout</h1>
    <v-stepper v-model="e1" class="ma-4 mt-2 rounded-0">
      <v-stepper-header>
        <v-stepper-step editable :complete="isDetailsFilled()" step="1">Details</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="isAddressFilled()" step="2" editable>Address</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">Payment</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <h1 class="ma-4 font-weight-regular">Customer Details</h1>
          <div class="my-8"/>
          <v-form ref="customerDetailsForm" lazy-validation>
            <v-row class="mx-2">
              <TextField
                  v-model="firstName"
                  label="First Name"
                  class="mx-2 my-2"
                  :rules="firstNameRules"
                  :maxlength="50"
              />
              <TextField
                  v-model="lastName"
                  label="Last Name"
                  class="mx-2 my-2"
                  :rules="lastNameRules"
                  :maxlength="50"
              />
            </v-row>
            <TextField
                v-model="phoneNumber"
                class="mx-4 my-2"
                label="Phone Number"
                icon="mdi-phone"
                :rules="phoneRules"
                :maxlength="8"
            />
            <TextField
                v-model="emailAddress"
                label="Email Address"
                class="mx-4 my-4"
                icon="mdi-email"
                type="email"
                :rules="emailRules"
                :maxlength="50"
            />
            <v-container class="pa-4">
              <Button title="Continue" @click="onCustomerDetailsDone" type="blocked"/>
            </v-container>
          </v-form>

        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form ref="addressForm" lazy-validation>
            <h1 class="ma-4 font-weight-regular">Billing Address</h1>
            <div class="my-8"/>
            <TextField
                v-model="billingFullAddress"
                label="Full Address"
                class="ma-4"
                :rules="addressRules"
            />
            <v-row class="mx-2">
              <TextField
                  v-model="billingUnitNumber"
                  label="Floor / unit number"
                  class="ma-2"
                  :rules="unitNumberRules"
              />
              <TextField
                  v-model="billingPostalCode"
                  label="Postal Code"
                  class="ma-2"
                  :maxlength="6"
                  :rules="postalCodeRules"
              />
            </v-row>
            <v-row class="mx-2" align="center">
              <v-row class="mx-2">Ship to different address?</v-row>
              <v-switch v-model="isDifferentAddress" color="primary" value class="mx-2"></v-switch>
            </v-row>
            <div v-if="isDifferentAddress">
              <h1 class="ma-4 mt-8 font-weight-regular">Shipping Address</h1>
              <div class="my-8"/>
              <TextField
                  v-model="shippingFullAddress"
                  label="Full Address"
                  class="ma-4"
                  :rules="addressRules"
              />
              <v-row class="mx-2">
                <TextField
                    v-model="shippingUnitNumber"
                    label="Floor / unit number"
                    class="ma-2"
                    :rules="unitNumberRules"
                />
                <TextField
                    v-model="shippingPostalCode"
                    label="Postal Code"
                    class="ma-2"
                    :maxlength="6"
                    :rules="postalCodeRules"
                />
              </v-row>
            </div>
            <v-container class="pa-4">
              <Button title="Go to Payment" @click="onAddressDone" type="blocked"/>
            </v-container>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-form ref="paymentForm" lazy-validation>
            <v-card min-width="450" class="elevation-0">
              <h2 class="ma-4 font-weight-regular">Payment Mode</h2>
              <v-row class="ma-2 mb-0">
                <Dropdown
                    v-model="payment"
                    :items="paymentItems"
                    :rules="paymentRules"
                    class="mx-2"
                    label="Select Payment Option"
                    @change="onPaymentChange"
                />
              </v-row>
              <v-col v-if="showPaymentNotes">
                <span>Notes:</span>
                <v-card max-width="450" class="grey lighten-3 pa-4 rounded-0 elevation-0 pb-16 my-1">Please pay now <b>SGD
                  ${{ this.totalPrice }}</b> to <b>UEN: 53349054E</b>. Confirm your order now and an e-mail with details will be sent
                  afterward.
                </v-card>
              </v-col>
              <v-container class="pa-4 mt-2">
                <Button title="Confirm Order" @click="onConfirmOrder" type="blocked" :loading="showLoading"/>
              </v-container>
            </v-card>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-layout>
</template>

<script>
import Button from "@/components/Button";
import Dropdown from "@/components/Dropdown";
import router from "@/router";
import TextField from "@/components/TextField";
import {addOrder} from "@/db/orders";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {TextField, Dropdown, Button},
  props: ['shippingMode', 'totalPrice'],
  computed: {
    ...mapGetters(['cartItems']),
  },
  created() {
    if (!this.totalPrice || !this.shippingMode) {
      router.push({name: 'Cart'});
    }
  },
  data() {
    return {
      showLoading: false,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      emailAddress: null,
      billingFullAddress: null,
      billingUnitNumber: null,
      billingPostalCode: null,
      shippingFullAddress: null,
      shippingUnitNumber: null,
      shippingPostalCode: null,
      isDifferentAddress: false,
      e1: 1,
      payment: '',
      showPaymentNotes: false,
      paymentItems: ['Pay Now'],
      paymentRules: [
        v => v?.length > 0 || 'Payment option is required'
      ],
      firstNameRules: [
        v => !!v || 'First name is required',
        v => /^[A-Za-z ]+$/.test(v) || 'First name must be valid',
      ],
      lastNameRules: [
        v => !!v || 'Last name is required',
        v => /^[A-Za-z ]+$/.test(v) || 'Last name must be valid',
      ],
      phoneRules: [
        v => !!v || 'Phone number is required',
        v => v?.length === 8 && !isNaN(v) || 'Phone Number must be valid',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      addressRules: [v => !!v || 'Full Address is required'],
      unitNumberRules: [v => !!v || 'Floor / unit number is required'],
      postalCodeRules: [
        v => !!v || 'Postal code is required',
        v => v?.length === 6 && !isNaN(v) || 'Postal code must be valid',
      ],
    }
  },
  methods: {
    ...mapActions(['resetCartItems']),
    async onConfirmOrder() {
      const validateDetails = this.$refs.customerDetailsForm?.validate();
      const validateAddress = this.$refs.addressForm?.validate();
      if (!validateDetails) {
        this.e1 = 1;
        return;
      }
      if (!validateAddress) {
        this.e1 = 2;
        return;
      }
      const validatePayment = this.$refs.paymentForm?.validate();
      if (!validatePayment) {
        return;
      }

      this.showLoading = true;
      const addOrderData = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        emailAddress: this.emailAddress,
        billingFullAddress: this.billingFullAddress,
        billingUnitNumber: this.billingUnitNumber,
        billingPostalCode: this.billingPostalCode,
        shippingFullAddress: this.shippingFullAddress ? this.shippingFullAddress : this.billingFullAddress,
        shippingUnitNumber: this.shippingUnitNumber ? this.shippingUnitNumber : this.billingUnitNumber,
        shippingPostalCode: this.shippingPostalCode ? this.shippingPostalCode : this.billingPostalCode,
        paymentMode: this.payment,
        orderedItems: this.cartItems,
        shippingMode: this.shippingMode,
        totalPrice: this.totalPrice
      };
      let orderId = await addOrder(addOrderData)
      this.resetCartItems();
      this.showLoading = false;

      await router.replace({
        name: 'Ordered', params: {
          orderId: orderId?.toString(),
          shippingMode: this.shippingMode,
          shippingAddress: addOrderData.shippingFullAddress + ', ' + addOrderData.shippingUnitNumber + ', ' + addOrderData.shippingPostalCode,
          billingAddress: addOrderData.billingFullAddress + ', ' + addOrderData.billingUnitNumber + ', ' + addOrderData.billingPostalCode,
          paymentMode: this.payment,
          totalPrice: this.totalPrice,
          orderedItems: addOrderData.orderedItems
        }
      })
    },
    onPaymentChange(item) {
      if (item === 'Pay Now') {
        this.showPaymentNotes = true;
      } else {
        this.showPaymentNotes = false;
      }
    },
    onCustomerDetailsDone() {
      const result = this.$refs.customerDetailsForm?.validate();
      if (result === false) return;
      this.e1 = 2;
    },
    onAddressDone() {
      const validateDetails = this.$refs.customerDetailsForm?.validate();
      if (!validateDetails) {
        this.e1 = 1;
        this.$refs.customerDetailsForm?.validate();
        return;
      }
      const validateAddress = this.$refs.addressForm?.validate();
      if (validateAddress === false) return;
      this.e1 = 3;
    },
    isDetailsFilled() {
      const isFilled = this.$refs.customerDetailsForm?.validate();
      // this.$refs.customerDetailsForm?.resetValidation();
      return isFilled;
    },
    isAddressFilled() {
      const isFilled = this.$refs.addressForm?.validate();
      this.$refs.addressForm?.resetValidation();
      return isFilled;
    }
  }
}
</script>

<style scoped>
</style>
