<template>
  <v-card max-width="160" class="ma-2 mx-1 elevation-0" @click="onClick">
    <v-card
        max-width="160"
        hover
        class="px-8 mb-4"
        height="200">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-img
              contain
              max-width="120"
              :lazy-src="imageUrl"
              :src="imageUrl"
          />
        </v-row>
      </v-container>
    </v-card>
    <div class="ma-1">
      <v-card-text class="pa-0">{{ title }}</v-card-text>
      <v-card-text class="pa-0 mt-2 font-weight-bold black--text">{{ price }}</v-card-text>
    </div>

  </v-card>

</template>

<script>
import router from '../router'

export default {
  props: ['product', 'onCardClick'],
  computed: {
    title() {
      if (this.product?.title?.length > 50) {
        return this.product?.title?.slice(0, 50) + '...';
      }
      return this.product?.title;
    },
    price() {
      const price = this.product?.price;
      return '$ ' + price;
    },
    imageUrl() {
      return this.product?.imageUrl;
    },

  },
  methods: {
    onClick() {
      if (this.onCardClick) {
        this.$emit('click')
        return;
      }
      if (this.product?.subtype === 'accessories' || this.product?.subtype === 'bags and toolbags') {
        router.push({name: 'AccessoriesDetails', params: {type: this.product?.subtype, id: this.product?.productId, product: this.product}});
      }
      else {
        router.push({name: 'FloorballDetails', params: {type: this.product?.subtype, id: this.product?.productId, product: this.product}});
      }
    }
  }
}
</script>

<style scoped>

</style>
