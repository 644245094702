<template>
  <v-row class="ma-0">
    <v-flex sm5 class="mx-4">
      <v-row align="center">
        <v-img contain max-width="100" max-height="100" :src="imageUrl"
               class="my-4"/>
        <v-col class="mx-4">
          <v-row @click="onTitle"><a class="black--text">{{ title }}</a></v-row>
          <v-row class="primary--text">{{ price }}</v-row>
          <v-row><a class="grey--text text--darken-2" @click="onRemove">Remove</a></v-row>
        </v-col>
      </v-row>

    </v-flex>

    <v-row align="center" justify="center" class="ma-4">
      <v-btn fab x-small color="black" @click="onRemoveCartItem">
        <v-icon color="white">mdi-minus</v-icon>
      </v-btn>
      <v-chip label class="mx-4">{{ cartQty }}</v-chip>
      <v-btn fab x-small color="black" @click="onAddCartItem">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-row align="center" justify="center" class="pa-4">
      <v-col class="ma-4">
        <v-row class="grey--text text--darken-2"><b>TOTAL</b></v-row>
        <v-row><b>{{ totalPrice }}</b></v-row>

      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import {mapActions} from "vuex";
import router from "@/router";

export default {
  props: ['cartItem'],
  methods: {
    ...mapActions(['incrementCartItem', 'decrementCartItem', 'removeCartItem']),
    onAddCartItem() {
      if (this.cartItem?.cartQty < this.cartItem?.quantity) {
        this.incrementCartItem(this.cartItem);
      }
    },
    onRemoveCartItem() {
      this.decrementCartItem(this.cartItem);
    },
    onRemove() {
      this.removeCartItem(this.cartItem);
    },
    onTitle() {
      if (this.cartItem?.subtype === 'accessories' || this.cartItem?.subtype === 'bags and toolbags') {
        router.push({name: 'AccessoriesDetails', params: {type: this.cartItem?.subtype, id: this.cartItem?.productId, product: this.cartItem}});
      }
      else {
        router.push({name: 'FloorballDetails', params: {type: this.cartItem?.subtype, id: this.cartItem?.productId, product: this.cartItem}});
      }
    }
  },
  computed: {
    title() {
      return this.cartItem?.title;
    },
    price() {
      return '$ ' + this.cartItem?.price;
    },
    imageUrl() {
      return this.cartItem?.imageUrl;
    },
    cartQty() {
      return this.cartItem?.cartQty;
    },
    totalPrice() {
      const price = this.cartItem?.price;
      const totalPrice = price * this.cartItem?.cartQty;
      return '$ ' + parseFloat(totalPrice).toFixed(2);
    }
  }
}
</script>

<style scoped>

</style>
