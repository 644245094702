<template>
  <v-overlay v-show="loading" opacity="0">
    <v-progress-circular
        indeterminate
        size="64"
        color="primary"
    />
  </v-overlay>
</template>

<script>
export default {
  props: ['loading']
}
</script>
