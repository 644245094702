<template>
  <v-btn
      class="font-weight-bold text-none rounded-0"
      :class="getType"
      :disabled="disabled"
      :loading="loading"
      @click="onClick"
      x-large
  >
    <v-icon class="mr-4" left v-show="icon">{{ icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: ['title', 'size', 'type', 'disabled', 'icon', 'loading'],
  computed: {
    getType() {
      switch (this.type) {
        case 'outlined':
          return "v-btn--outlined primary--text secondary";
        case 'contained':
          return "theme--dark v-btn--contained primary secondary--text";
        case 'apply':
          return "theme--dark v-btn--contained black";
        case 'secondary':
          return "v-btn white--text secondary";
        case 'blocked':
          return "v-btn--block primary v-size--x-large secondary--text";
      }
      return "theme--dark v-btn--contained primary secondary--text";
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
