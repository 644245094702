import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

import cartStore from "./modules/CartStore";

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
        storage: window.sessionStorage,
    }),
        createMutationsSharer({ predicate: ["incrementCartItem", "addCartItem", "decrementCartItem", "removeCartItem", "resetCartItems"] })
    ],
    modules: {
        cartStore
    },
});
