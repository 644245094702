<template>
  <div>
    All
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
