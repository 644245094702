<template>
  <div>
    <v-container fluid class="align-center justify-center fill-height ma-8">
      <h2>Login not available at this moment.</h2>
    </v-container>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
