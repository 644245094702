import data1 from './1.json';
import data2 from './2.json';
import data3 from './3.json';
import data4 from './4.json';
import data5 from './5.json';
import data6 from './6.json';
import data7 from './7.json';
import data8 from './8.json';
import data9 from './9.json';
import data10 from './10.json';
import data11 from './11.json';
import data12 from './12.json';
import data13 from './13.json';
import data14 from './14.json';
import data15 from './15.json';
import data16 from './16.json';
import data17 from './17.json';
import data18 from './18.json';
import data19 from './19.json';
import data20 from './20.json';
import data21 from './21.json';
import data22 from './22.json';
import data23 from './23.json';
import data24 from './24.json';
import data25 from './25.json';
import data26 from './26.json';
import data27 from './27.json';
import data28 from './28.json';
import data29 from './29.json';
import data30 from './30.json';
import data31 from './31.json';
import data32 from './32.json';
import data33 from './33.json';
import data34 from './34.json';
import data35 from './35.json';
import data36 from './36.json';
import data37 from './37.json';
import data38 from './38.json';
import data39 from './39.json';
import data40 from './40.json';
import data41 from './41.json';
import data42 from './42.json';
import data43 from './43.json';
import data44 from './44.json';
import data45 from './45.json';
import data46 from './46.json';
import data47 from './47.json';
import data48 from './48.json';
import data49 from './49.json';
import data50 from './50.json';
import data51 from './51.json';
import data52 from './52.json';
import data53 from './53.json';
import data54 from './54.json';
import data55 from './55.json';
import data56 from './56.json';
import data57 from './57.json';
import data58 from './58.json';
import data59 from './59.json';
import data60 from './60.json';
import data61 from './61.json';
import data62 from './62.json';
import data63 from './63.json';
import data64 from './64.json';
import data65 from './65.json';
import data66 from './66.json';
import data67 from './67.json';
import data68 from './68.json';
import data69 from './69.json';
import data70 from './70.json';
import data71 from './71.json';
import data72 from './72.json';
import data73 from './73.json';
import data74 from './74.json';
import data75 from './75.json';
import data76 from './76.json';
import data77 from './77.json';
import data78 from './78.json';
import data79 from './79.json';
import data80 from './80.json';
import data81 from './81.json';
import data82 from './82.json';
import data83 from './83.json';
import data84 from './84.json';
import data85 from './85.json';
import data86 from './86.json';
import data87 from './87.json';
import data88 from './88.json';
import data89 from './89.json';
import data90 from './90.json';
import data91 from './91.json';
import data92 from './92.json';
import data93 from './93.json';
import data94 from './94.json';
import data95 from './95.json';
import data96 from './96.json';
import data97 from './97.json';
import data98 from './98.json';
import data99 from './99.json';
import data100 from './100.json';
import data101 from './101.json';
import data102 from './102.json';
import data103 from './103.json';
import data104 from './104.json';
import data105 from './105.json';
import data106 from './106.json';
import data107 from './107.json';
import data108 from './108.json';
import data109 from './109.json';
import data110 from './110.json';
import data111 from './111.json';

export const data = [
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12,
    data13,
    data14,
    data15,
    data16,
    data17,
    data18,
    data19,
    data20,
    data21,
    data22,
    data23,
    data24,
    data25,
    data26,
    data27,
    data28,
    data29,
    data30,
    data31,
    data32,
    data33,
    data34,
    data35,
    data36,
    data37,
    data38,
    data39,
    data40,
    data41,
    data42,
    data43,
    data44,
    data45,
    data46,
    data47,
    data48,
    data49,
    data50,
    data51,
    data52,
    data53,
    data54,
    data55,
    data56,
    data57,
    data58,
    data59,
    data60,
    data61,
    data62,
    data63,
    data64,
    data65,
    data66,
    data67,
    data68,
    data69,
    data70,
    data71,
    data72,
    data73,
    data74,
    data75,
    data76,
    data77,
    data78,
    data79,
    data80,
    data81,
    data82,
    data83,
    data84,
    data85,
    data86,
    data87,
    data88,
    data89,
    data90,
    data91,
    data92,
    data93,
    data94,
    data95,
    data96,
    data97,
    data98,
    data99,
    data100,
    data101,
    data102,
    data103,
    data104,
    data105,
    data106,
    data107,
    data108,
    data109,
    data110,
    data111,
]
