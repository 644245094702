<template>
  <div>
    <div class="my-16">
      <v-row class="mx-8 my-4">
        <h3>FEATURED PRODUCTS</h3>
      </v-row>
      <v-sheet class="mx-auto grey lighten-5 py-4 px-2 my-4">
        <v-slide-group active-class="success">
          <v-slide-item v-for="(product, index) in featuredProducts" :key="index">
            <Card :product="product" class="mr-2"/>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>

    <div class="my-16">
      <v-row class="mx-8 my-4">
        <h3>FLOORBALL</h3>
        <v-spacer></v-spacer>
        <router-link to="/floorball" tag="Button">
          <h4 class="primary--text">View All</h4>
        </router-link>
      </v-row>
      <v-sheet class="mx-auto grey lighten-5 py-4 px-2 my-4">
        <v-slide-group active-class="success">
          <v-slide-item v-for="(product, index) in floorballProducts" :key="index">
            <Card :product="product" class="mr-2"/>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>

    <div class="my-16">
      <v-row class="mx-8 my-4">
        <h3>ACCESSORIES</h3>
        <v-spacer></v-spacer>
        <router-link to="/accessories" tag="Button">
          <h4 class="primary--text">View All</h4>
        </router-link>
      </v-row>
      <v-sheet class="mx-auto grey lighten-5 py-4 px-2 my-4">
        <v-slide-group active-class="success">
          <v-slide-item v-for="(product, index) in accessoriesProducts" :key="index">
            <Card :product="product" class="mr-2"/>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import {getHomeAccessoriesProducts, getHomeFeaturedProducts, getHomeFloorballProducts} from "@/db/products";

export default {
  name: 'Home',
  components: {Card},
  computed: {},
  async created() {
    this.featuredProducts = await getHomeFeaturedProducts();
    this.floorballProducts = await getHomeFloorballProducts();
    this.accessoriesProducts = await getHomeAccessoriesProducts();
  },
  data() {
    return {
      products: null,
      featuredProducts: null,
      floorballProducts: null,
      accessoriesProducts: null
    }
  }
}
</script>

<style scoped>

</style>
