<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="1000"
      @keydown.esc="onClose">
    <v-card>
      <v-card-title>
        <v-card-title class="mx-auto">{{ title }}</v-card-title>
        <v-icon @click="onClose">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <slot/>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  props: ['title', 'dialog'],
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
