<template>
  <div>
    <Progress :loading="loading"/>
    <div class="px-8 py-8 row" v-if="!loading">
      <a @click="onTypeClick"><h1 class="black--text">{{ type }}</h1></a>
      <h1 class="grey--text mx-2">/</h1>
      <a @click="onSubtypeClick"><h1>{{ subtype }}</h1></a>
    </div>
    <div class="grey lighten-4 py-8 px-8" v-if="!loading">
      <v-row justify="start" align="start">
        <v-img
            contain
            max-width="500"
            :lazy-src="imageUrl"
            :src="imageUrl"
            class="ma-4"
        />
        <div class="my-2 mx-4">
          <h1 class="font-weight-regular grey--text text--darken-3">{{ title }}</h1>
          <div class="my-1"/>
          <h2>{{ price }}</h2>
          <div class="my-4"/>
          <div v-if="quantity > 0">
            <v-row v-if="hasAdded">
              <a class="text--darken-2 mx-2 my-8 text-decoration-underline error--text" @click="onRemoveCart">Remove
                this item from cart</a></v-row>
            <v-row class="my-8 mx-0" v-if="!hasAdded">
              <v-btn fab x-small color="black" @click="onDecrementItem">
                <v-icon color="white">mdi-minus</v-icon>
              </v-btn>
              <v-chip label class="mx-4">{{ itemCount }}</v-chip>
              <v-btn fab x-small color="black" @click="onIncrementItem">
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </div>
          <div v-else class="my-8">
            <h2 class="primary--text">SOLD OUT</h2>
          </div>
          <v-row>
            <Button v-if="!hasAdded" type="contained" title="Add to Cart" @click="onAddCart" class="ma-2"/>
            <v-badge
                color="success"
                :content="cartQty"
                :value="hasAdded"
                class="ma-2"
            >
              <Button v-if="hasAdded" type="contained" title="Added to Cart" class="text--disabled"/>
            </v-badge>
            <v-btn
                class="font-weight-bold text-none rounded-0 v-btn--outlined primary--text secondary"
                :class="hasAdded ? 'ma-2 mx-4' : 'ma-2'"
                x-large
            >
              <a href="https://wa.me/6590232371" target="_blank" class="text-decoration-none">Request Info</a>
            </v-btn>
          </v-row>
          <v-divider class="my-8 grey lighten-1"></v-divider>
          <div class="max-summary grey--text text--darken-1">{{ summary }}</div>
        </div>
      </v-row>

    </div>
  </div>
</template>

<script>
import Progress from "@/components/Progress";
import {getProduct} from "@/db/products";
import Button from "@/components/Button";
import {mapActions, mapGetters} from "vuex";
import router from "@/router";

export default {
  components: {Button, Progress},
  data() {
    return {
      loading: false,
      product: null,
      itemCount: 1,
      cartQty: null,
      hasAdded: false
    }
  },
  computed: {
    ...mapGetters(['cartItems']),
    title() {
      return this.product?.title;
    },
    imageUrl() {
      return this.product?.imageUrl;
    },
    price() {
      return this.product?.price ? '$ ' + this.product?.price : '';
    },
    summary() {
      return this.product?.summary;
    },
    subtype() {
      const subtype = this.product?.subtype;
      return subtype?.charAt(0)?.toUpperCase() + subtype?.slice(1);
    },
    type() {
      const type = this.product?.type;
      return type?.charAt(0)?.toUpperCase() + type?.slice(1);
    },
    quantity() {
      return this.product?.quantity;
    }
  },
  async created() {
    this.hasAdded = false;
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
      const checkProductId = this.cartItems.findIndex(item => item.id === this.product.productId);
      if (checkProductId !== -1) {
        this.hasAdded = true;
        this.cartQty = this.itemCount;
      }
      return;
    }
    this.loading = true;
    const productId = this.$route.params.id;
    this.product = await getProduct(productId);
    this.loading = false;
    const checkProductId = this.cartItems.findIndex(item => item.id === productId);
    if (checkProductId !== -1) {
      this.hasAdded = true;
      this.cartQty = this.itemCount;
    }
  },
  methods: {
    ...mapActions(['addCartItem', 'removeCartItem']),
    onAddCart() {
      if (this.quantity < 1) return;
      this.addCartItem({id: this.$route.params.id, cartQty: this.itemCount, ...this.product});
      const productId = this.cartItems.findIndex(item => item.id === this.product.productId);
      if (productId !== -1) {
        this.hasAdded = true;
        this.cartQty = this.itemCount;
      }
    },
    onRemoveCart() {
      this.removeCartItem(this.product);
      this.hasAdded = false;
    },
    onTypeClick() {
      if (this.product?.type) {
        router.push({name: this.product?.type})
      }
    },
    onSubtypeClick() {
      if (this.product?.type) {
        router.push({name: this.product?.type, params: {subtype: this.product?.subtype}})
      }
    },
    onIncrementItem() {
      if (this.itemCount < this.product?.quantity) this.itemCount++;
    },
    onDecrementItem() {
      if (this.itemCount > 1) this.itemCount--;
    },

  }
}
</script>

<style scoped>
.max-summary {
  max-width: 800px;
}
</style>
