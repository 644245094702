<template>
  <div>
    <v-app-bar
        absolute
        fixed
        scroll-target="#scrolling-techniques"
        class="elevation-0 white pr-0 flex-column py-12"
        extended
        extension-height="72"
        hide-on-scroll
    >
      <v-col class="pl-4 pr-0 pt-8 pb-12">
        <v-row align="center" class="ma-0 pa-0">
        <h1>Accessories</h1>
        <v-flex xs3 v-if="isSearching" class="mt-2 mx-3">
          <v-text-field
              v-model="searchField"
              autofocus
              single-line
              dense
              background-color="rounded-0 elevation-0"
          />
        </v-flex>
        <v-icon v-if="!isSearching" class="mx-4" @click="isSearching = true">mdi-magnify</v-icon>
        <v-icon v-if="isSearching" class="mx-2" @click="onCancelSearch">mdi-close</v-icon>
        </v-row>
        <div class="my-2"/>
        <v-row :cols="tags.length" class="ma-0">
          <v-chip-group row mandatory active-class="primary" v-model="filterId">
            <v-chip v-for="tag in tags" :key="tag" active-class="font-weight-bold primary">
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-col>

    </v-app-bar>
    <v-sheet id="scrolling-techniques" class="overflow-y-auto ma-0" max-height="1000" style="padding-top: 140px;">
      <v-container fill-height fluid class="grey fill-height lighten-5 pa-8 align-start justify-start">
        <v-card-text v-show="productsSize === 0" class="text-center ma-16">No Results Found</v-card-text>
        <Card
            v-for="(product, index) in getProducts"
            :product="product"
            :key="index"
        />
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import {getAllProducts} from "@/db/products";
import Card from "@/components/Card";

export default {
  components: {Card},
  data() {
    return {
      searchField: '',
      isSearching: false,
      products: null,
      filterId: 0,
      tags: [
        'All',
        'Bags and Toolbags',
      ],
    }
  },
  methods: {
    onCancelSearch() {
      this.isSearching = false;
      this.searchField = '';
    }
  },
  computed: {
    allProducts() {
      return this.products;
    },
    productsSize() {
      return this.getProducts?.length;
    },
    getProducts() {
      let category = this.tags[this.filterId];
      let filteredProducts = (category === 'All') ? this.products : this.products.filter(product =>
          product?.subtype?.toLowerCase() === category?.toLowerCase());
      if (this.searchField === "") {
        return filteredProducts;
      }
      return filteredProducts.filter(product =>
          product?.title.toLowerCase().indexOf(this.searchField.toLowerCase()) >= 0
      );
    }
  },
  async created() {
    this.products = await getAllProducts("accessories");
    if (this.$route.params.subtype) {
      const index = this.tags.findIndex(item => item?.toLowerCase() === this.$route.params.subtype?.toLowerCase());
      if (index > 0) {
        this.filterId = index;
      }
    }
  },
}
</script>

<style scoped>
</style>
