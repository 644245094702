import {productsCollection} from "../../firebase";

export const addProduct = (productId, productDetails) => {
    return new Promise(resolve => {
        productsCollection
            .doc(productId)
            .set(productDetails)
            .then(() => resolve(productId));
    });
}

export const getProduct = (productId) => {
    return new Promise(resolve => {
        productsCollection
            .doc(productId)
            .get()
            .then(doc => doc?.exists ? resolve(doc?.data()) : resolve(null));
    });
}

export const getAllProducts = (type) => {
    return new Promise(resolve => {
        productsCollection
            .where("type", "==", type)
            .onSnapshot(
                {includeMetadataChanges: true},
                (snapshot => {
                    let products = [];
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            products.push({productId: change.doc.id, ...change.doc.data()})
                        }
                    });
                    resolve(products);
                }))
    })
}

export const getHomeFeaturedProducts = () => {
    return new Promise(resolve => {
        productsCollection
            .limit(20)
            .onSnapshot(
                {includeMetadataChanges: true},
                (snapshot => {
                    let products = [];
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            products.push({productId: change.doc.id, ...change.doc.data()})
                        }
                    });
                    resolve(products);
                }))
    })
}

export const getHomeFloorballProducts = () => {
    return new Promise(resolve => {
        productsCollection
            .where('subtype', 'in', ['blades', 'goalies', 'floorball sticks', 'hall equipment', 'clothes'])
            .limit(20)
            .onSnapshot(
                {includeMetadataChanges: true},
                (snapshot => {
                    let products = [];
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            products.push({productId: change.doc.id, ...change.doc.data()})
                        }
                    });
                    resolve(products);
                }))
    })
}

export const getHomeAccessoriesProducts = () => {
    return new Promise(resolve => {
        productsCollection
            .where('subtype', 'in', ['accessories', 'bags and toolbags'])
            .limit(20)
            .onSnapshot(
                {includeMetadataChanges: true},
                (snapshot => {
                    let products = [];
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            products.push({productId: change.doc.id, ...change.doc.data()})
                        }
                    });
                    resolve(products);
                }))
    })
}
